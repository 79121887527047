import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

// Components
import Layout from "@components/layout"
import Container from "@components/container"
import Anchor from "@components/anchor"

import { Heading1 } from "@styles/ui"

// Assets
import logo from "@images/logos/logo-colourful-slight-transparency.svg"
import clinicalDataIcon from "@images/icons/icon-gradient-clinical-data.svg"
import symptomsIcon from "@images/icons/icon-gradient-symptoms.svg"

const SignUpThankYouPage = ({ location }) => {
  const ctaData = [
    {
      text: "Discover XYWAV narcolepsy clinical trial data",
      url: "/narcolepsy/efficacy-and-safety/",
      icon: clinicalDataIcon,
    },
    {
      text: "Learn about idiopathic hypersomnia symptoms",
      url: "/idiopathic-hypersomnia/diagnosis/",
      icon: symptomsIcon,
    },
  ]

  return (
    <Layout
      location={location}
      mainBg="seashell"
      copyright={
        <>
          © 2024 Jazz Pharmaceuticals plc or its subsidiaries{" "}
          <br tw="block md:hidden" />
          US-XYW-2200207 Rev0824
        </>
      }
    >
      {/* not using Section component as padding doesn't match other types */}
      <section tw="bg-alabaster-2 relative overflow-hidden pt-[70px] pb-[125px] rounded-br-[100px]md:(pt-10 pb-[165px] ) xl:(py-[120px] rounded-none)">
        <img
          src={logo}
          tw="absolute right-[-35px] bottom-[-92px] w-[340px] h-[220px] md:(bottom-[10px] right-[-50px]) xl:(w-[920px] h-[595px] bottom-[-225px] right-[-100px])"
          alt=""
        />
        <Container>
          <h1 css={[Heading1, tw`xl:(text-[40px] max-w-[700px])`]}>
            <strong>Thank you</strong> for signing up to learn more about XYWAV!
          </h1>
          <p tw="text-2xl mt-5 md:(max-w-[550px]) xl:(text-4xl mt-[30px] max-w-[700px])">
            You&apos;ll receive useful information about XYWAV and how it can
            help your patients with narcolepsy or idiopathic hypersomnia.
          </p>
        </Container>
      </section>
      <section tw="bg-seashell relative py-[50px] md:(pt-[50px]) xl:(bg-seashell pb-[80px] pt-[60px])">
        <Container>
          <h2 tw="text-4xl font-normal">
            <strong>Explore</strong> more about XYWAV
          </h2>
          <div tw="md:(flex column-gap[30px])">
            {ctaData &&
              ctaData.map((cta, i) => (
                <div
                  key={i}
                  tw="mt-[30px] bg-white rounded-[40px] flex flex-col items-center justify-center py-[45px] px-[30px] md:(py-10 px-[25px] flex-1)"
                >
                  <img
                    src={cta.icon}
                    tw="w-[70px] h-[70px] mx-auto mb-5 md:(w-[75px] h-[75px]) xl:(w-[90px] h-[90px] mb-[30px])"
                    alt=""
                  />
                  <strong>
                    <Anchor
                      link={cta.url}
                      underline
                      addedStyles={tw`text-xs max-w-[70%] block mx-auto text-center md:(max-w-[280px]) xl:(text-xl)`}
                    >
                      {cta.text}
                    </Anchor>
                  </strong>
                </div>
              ))}
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default SignUpThankYouPage

SignUpThankYouPage.propTypes = {
  location: PropTypes.object.isRequired,
}
